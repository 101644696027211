var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.valores !== null && !_vm.isLoading)?_c('v-data-table',{attrs:{"dense":"","fixed-header":"","multi-sort":"","headers":_vm.headers,"items":_vm.valores,"locale":"es-es","loading":_vm.cargando,"options":_vm.compPagination,"loading-text":"Loading... Please wait","items-per-page":10,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      'items-per-page-options': [15, 30, 50, 100],
      'items-per-page-text': 'Filas por página'
    }},on:{"update:options":function($event){_vm.compPagination=$event}},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","x-small":"","color":_vm.getColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:`item.action`,fn:function({ item }){return [_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.abrirPDFFicha(item)}}},[_vm._v(" mdi-file-pdf-box ")]),(_vm.gtrUserData.id === 1 && _vm.gtrUserData.username === 'userroot')?[_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.eliminarFicha(item)}}},[_vm._v(" mdi-delete ")])]:_vm._e()]}}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }