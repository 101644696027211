<template>
  <div>
    <v-data-table
      dense
      fixed-header
      v-if="valores !== null && !isLoading"
      multi-sort
      :headers="headers"
      :items="valores"
      locale="es-es"
      :loading="cargando"
      :options.sync="compPagination"
      loading-text="Loading... Please wait"
      :items-per-page="10"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-options': [15, 30, 50, 100],
        'items-per-page-text': 'Filas por página'
      }"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip label x-small :color="getColor(item.status)" dark>{{
          item.status
        }}</v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small color="primary" @click="editar(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="ml-2" color="primary" @click="abrirPDFFicha(item)">
          mdi-file-pdf-box
        </v-icon>
        <template
          v-if="gtrUserData.id === 1 && gtrUserData.username === 'userroot'"
        >
          <v-icon small class="ml-2" color="red" @click="eliminarFicha(item)">
            mdi-delete
          </v-icon>
        </template>
      </template>
    </v-data-table>
  </div>
  <!--     {{ pagination }} -->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Ficha-table",
  props: ["valores", "cargando", "loadPagination", "paginaActual"],
  data: () => ({
    currentPage: 1,
    isLoading: true,
    pagination: {},
    headers: [
      {
        text: "id",
        align: " d-none",
        value: "id",
      },
      {
        text: "Nombre",
        align: "left",
        value: "name",
      },
      {
        text: "Cod.Postal Pobl Prov",
        align: "left",
        value: "address",
      },
      {
        text: "Dirección",
        align: "left",
        value: "address2",
      },
      {
        text: "Teléfono",
        align: "left",
        value: "phone",
      },
      {
        text: "Fecha Alta",
        align: "center",
        value: "creationdate",
      },
      {
        text: "Estado",
        align: "center",
        value: "status",
      },
      {
        text: "Acciones",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
  }),

  methods: {
    ...mapMutations("records", ["SET_RECORD_PAGINATION"]),
    eliminarFicha(elemento) {
      this.$emit("emit-eliminar-ficha", elemento);
    },
    getColor(estado) {
      switch (estado) {
        case "ALTA":
          return "green";
        case "BAJA":
          return "gray";
        case "PENDIENTE DE ANEXO":
          return "orange";
        default:
          return "red";
      }
    },

    editar(item) {
      /*   localStorage.setItem(
        "currentPagination",
        JSON.stringify(this.pagination)
      ); */

      this.$router.push({ name: "fdetalle", params: { id: item.reco_id } });
    },
    /* cargamos los datos paginación del store */
    cargarPaginacion() {
      this.pagination = { ...this.recordPagination };
    },
    abrirPDFFicha(item){
      window.open("/api-v-57/public/pdfFicha/"+item.reco_id, "_blank");
    }
  },
  computed: {
    ...mapGetters("user", ["gtrUserData"]),
    ...mapGetters("records", ["recordPagination"]),
    compPagination: {
      get() {
        return this.recordPagination;
      },
      set(newValue) {
        this.SET_RECORD_PAGINATION({ ...newValue });
      },
    },
  },

  mounted() {
    if (this.loadPagination) {
      this.cargarPaginacion();
    }

    this.isLoading = false;
  },
};
</script>

<style scoped>
.d-none {
  display: none !important;
}
::v-deep.v-data-table td {
  font-size: 0.76em;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep.v-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>
