<template>
  <v-container fluid>
    <v-card class="mx-auto mb-3" elevation="5" outlined>
      <v-toolbar color="indigo darken-4" dark dense>
        <v-toolbar-title>Fichas</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-list-item three-line>
        <v-list-item-content>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="filtro.name"
                label="Nombre"
                dense
                outlined
                autocomplete="nane"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="filtro.address"
                label="Cod. Postal | Población | Provincia"
                dense
                outlined
                autocomplete="nane"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.phone"
                label="Teléfono"
                dense
                outlined
                autocomplete="nane"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="filtro.status"
                label="Estado"
                :items="statusesItems"
                dense
                outlined
                :menu-props="{
                  closeOnContentClick: true,
                  offsetY: true,
                  maxHeight: 650,
                }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.status = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-combobox
                  :items="tipoGuia"
                  v-model="filtro.tipoGuia"
                  label="Tipo de Guía"
                  item-text="tipo"
                  outlined
                  dense
                  clearable
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.creationdateIni"
                type="date"
                label="Fecha Alta (desde)"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.creationdateEnd"
                type="date"
                label="Fecha Alta (hasta)"
                dense
                outlined
                class="text-right"
              ></v-text-field>
            </v-col>

            <!--   <v-col cols="12" md="3">
              <v-text-field
                v-model="filtro.catNombres"
                label="Categoria"
                dense
                outlined
                clearable
                autocomplete="catNombres-new"
              ></v-text-field>
            </v-col> -->

            <!--      <v-col cols="12" md="3">
                <v-text-field
                v-model="filtro.espNombres"
                label="Especialidades"
                dense
                outlined
                clearable
                autocomplete="catEspecialidades-new"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="3">
              <v-combobox
                :items="categoriesItems"
                v-model="filtro.catNombres"
                label="Categorías"
                item-text="name"
                outlined
                dense
                @click:clear="filtro.catNombres = null"
                clearable
              ></v-combobox>
              <!--  <v-text-field
                v-model="filtro.catNombres"
                label="Categoria"
                dense
                outlined
                clearable
                autocomplete="catNombres-new"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="3">
              <v-combobox
                :items="especialidadesItems"
                v-model="filtro.espNombres"
                label="Especialidades"
                item-text="name"
                outlined
                dense
                clearable
              ></v-combobox>
              <!--  <v-text-field
                v-model="filtro.espNombres"
                label="Especialidades"
                dense
                outlined
                clearable
                autocomplete="catEspecialidades-new"
              ></v-text-field> -->
            </v-col>

            <!--           <v-col cols="12" md="3">
              <v-select
                v-model="filtro.specialties"
                label="Categorías"
                :items="categoriesItems"
                item-value="id"
                item-text="name"
                dense
                outlined
                clearable
                :menu-props="{ closeOnContentClick: true, offsetY: true }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.specialties = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col> -->
            <!-- 
            <v-col cols="12" md="3">
              <v-select
                v-model="filtro.subCategorias"
                label="Especialidades"
                :items="especialidadesItems"
                item-value="id"
                item-text="name"
                dense
                outlined
                clearable
                :menu-props="{ closeOnContentClick: true, offsetY: true }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.specialties = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col> -->
            <v-col cols="12" md="2">
              <v-tooltip top color="orange">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right ml-1"
                    color="orange"
                    @click="crearNuevaFicha"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nueva ficha</span>
              </v-tooltip>

              <v-tooltip top color="blue">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right ml-1"
                    color="blue"
                    @click="refrescarDatos"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar datos</span>
              </v-tooltip>
              <v-tooltip left color="purple">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right"
                    color="purple"
                    @click="resetFiltros"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar formulario</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="loading"> </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-row>
          <v-col cols="12" md="12">
            <fichas-table
              v-if="!loading"
              :valores="valores"
              :cargando="loading"
              :loadPagination="recargarDatosTabla"
              @emit-eliminar-ficha="eliminarFicha"
            ></fichas-table>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card>
    <dialog-comp
      :mostrar="dialogComp.mostrar"
      @emitAceptar="eliminarFichaDialog"
      @emitCancelar="dialogComp.mostrar = false"
      :cabecera="dialogComp.cabecera"
      :cuerpo="dialogComp.cuerpo"
      :maxWidth="450"
    ></dialog-comp>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FichasTable from "@/components/fichas/parts/FichaTable";
import DialogComp from "@/components/comun/Dialog";

import _ from "lodash";

import moment from "moment";

export default {
  name: "fichas",
  metaInfo: {
    title: 'Fichas'
  },

  components: {
    FichasTable,
    DialogComp,
  },

  data: () => ({
    recargarDatosTabla: false,
    loading: true,
    datos: null,
    datosTratados: null,
    estados: null,
    categorias: null,
    subCategorias: null,
    tipoGuia: null,
    filtro: {
      name: null,
      address: null,
      phone: null,
      status: null,
      creationdateIni: null,
      creationdateEnd: null,
      specialties: null,
      subCategorias: null,
      catNombres: null,
      espNombres: null,
      tipoGuia: null,
    },
    anteriorFiltro: null,

    debCatNombres: null,
    debEspNombres: null,

    // variables par la busqueda de cat/esp

    recargaLocal: false,
    dialogComp: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
  }),

  methods: {
    ...mapActions("records", ["setRecordAll", "setRecordDelete"]),
    ...mapActions("comun", ["setSnackBar"]),
    ...mapMutations("records", [
      "SET_RECORD_RECORD",
      "SET_RECORD_FILTER",
      "SET_RECORDS_RELOAD",
      "SET_RECORD_PAGINATION",
    ]),

    /* funcion para eliminar una ficha */
    eliminarFicha(ficha) {
      this.dialogComp.cabecera = `Eliminar ficha id &nbsp;${ficha.reco_id}`;
      this.dialogComp.cuerpo =
        "Procederá a eliminar una ficha, ¿Está seguro de proceder?";
      this.dialogComp.id = ficha;
      this.dialogComp.mostrar = true;
    },
    /* gestionamos lo que emite el dialog */
    async eliminarFichaDialog() {
      try {
        const res = await this.setRecordDelete(this.dialogComp.id.reco_id);

        if (res.success) {
          const miSnack = {
            mostrar: true,
            texto: "Eliminada la ficha correctamente",
            color: "success",
          };
          this.setSnackBar(miSnack);

          this.datosTratados = this.datosTratados.filter(
            (d) => parseInt(d.reco_id) !== parseInt(res.data)
          );

          // actualizamos el store
          const datosFiltrados = [...this.datos].filter(
            (d) => parseInt(d.reco_id) !== parseInt(res.data)
          );
          this.SET_RECORD_RECORD(datosFiltrados);
        }
      } catch (error) {
        const miSnack = {
          mostrar: true,
          texto: "Error al tratar de eliminar la ficha",
          color: "warning",
        };
        this.setSnackBar(miSnack);
        // eslint-disable-next-line no-console
        console.log(error);
      }
      // finalmente vaciamos los datos del dialogo
      this.dialogComp = {
        mostrar: false,
        cabecera: null,
        cuerpo: null,
        id: null,
      };
    },

    /* funcion para generar un array con los datos estáticos - agilidad filtrando */
    generarDatosTratados() {
      const salida = this.datos.reduce((acc, curr, index) => {
        const direccion = `${curr.zico_code} - ${curr.zico_town} (${curr.zico_province})`;
        // recopilamos los id únicos de categorias
        const specialties = [
          ...new Set(curr.record_specialties.map((s) => s.resp_idcategory)),
        ];
        // recopilamos los id únicos de especialidades
        const subcategorias = [
          ...new Set(curr.record_specialties.map((s) => s.resp_idsubcategory)),
        ];

        // generamos las cadenas de texto para buscar por nombre en cat / esp
        const catNombres = [];
        const espNombres = [];
        specialties.forEach((s) => {
          const ft = this.categorias.find((c) => c.cate_id === s);
          if (ft) {
            catNombres.push(ft.cate_name);
          }
        });
        subcategorias.forEach((s) => {
          const ft = this.subCategorias.find((c) => c.subc_id === s);
          if (ft) {
            espNombres.push(ft.subc_name);
          }
        });

        acc.push({
          id: `${curr.reco_id}_${curr.read_id}`,
          reco_id: curr.reco_id,
          name: curr.reco_name,
          address: direccion,
          address2: curr.reco_address,
          phone: curr.reco_phone,
          creationdate: moment(curr.reco_creationdate).format("DD/MM/YYYY"),
          reco_idguiastipo: curr.reco_guiastipo ? curr.reco_idguiastipo : null,
          status: this.estados.find((s) => s.stat_id === curr.reco_idstatus)
            .stat_name,
          specialties,
          subcategorias,
          catNombres,
          espNombres,
        });

        return acc;
      }, []);

      this.datosTratados = salida;
    },
    /* funcion para crear una nueva ficha */
    crearNuevaFicha() {
      this.$router.push("fnew");
    },

    /* funcion para refrescar los datos de la bd */
    refrescarDatos() {
      // si tenemos marcado el recordReload del store recargamos
      if (this.recordsReload) {
        this.$store.commit("comun/SET_MAIN_LOADER", true);

        this.setRecordAll().then((res) => {
          this.datos = res.records;
          this.estados = res.estado;
          this.categorias = res.categorias;
          this.subCategorias = res.subcategorias;
          this.tipoGuia = res.tipoGuia;
          //generamos un array estático con todos los datos descargados - para después tratarlo con los filtros
          this.generarDatosTratados();
          this.$store.commit("comun/SET_MAIN_LOADER", false);
          this.cargarFiltros();
        });
        return;
      }
      //si volvemos recuperamos los datos en local
      if (this.recargaLocal) {
        this.datos = this.recordAll.records;
        this.estados = this.recordAll.estado;
        this.categorias = this.recordAll.categorias;
        this.subCategorias = this.recordAll.subcategorias;
        this.tipoGuia = this.recordAll.tipoGuia;
        this.generarDatosTratados();
        this.recargaLocal = false;
        this.cargarFiltros();
        return;
      }

      this.recargarDatosTabla = false;
      this.SET_RECORD_PAGINATION(null);

      this.resetFiltros();
      this.$store.commit("comun/SET_MAIN_LOADER", true);
      this.setRecordAll().then((res) => {
        this.datos = res.records;
        this.estados = res.estado;
        this.categorias = res.categorias;
        this.subCategorias = res.subcategorias;
        this.tipoGuia = res.tipoGuia;
        //generamos un array estático con todos los datos descargados - para después tratarlo con los filtros
        this.generarDatosTratados();
        this.SET_RECORDS_RELOAD(false);
        this.loading = false;
        this.$store.commit("comun/SET_MAIN_LOADER", false);
      });
    },

    /* funcion para limpiar los filtros */
    resetFiltros() {
      this.SET_RECORD_PAGINATION(null);
      Object.keys(this.filtro).forEach((e) => (this.filtro[e] = null));
      this.debCatNombres = null;
      this.debEspNombres = null;
    },

    /* funcion para cargar filtros desde localstore */
    cargarFiltros() {
      const datosLocales = JSON.parse(localStorage.getItem("currentFilter"));
      if (datosLocales) {
        this.filtro = datosLocales;
      }
      // cargamos los filtros desde el store
      // this.filtro = { ...this.recordFilter };
      if (this.filtro.espNombres !== null) {
        this.debEspNombres = this.filtro.espNombres;
      }
      if (this.filtro.catNombres !== null) {
        this.debCatNombres = this.filtro.catNombres;
      }
      this.loading = false;
      this.SET_RECORDS_RELOAD(false);
    },
  },
  computed: {
    ...mapGetters("records", [
      "recordAll",
      "recordFilter",
      "recordsReload",
      "recordPagination",
    ]),
    /* especialidades */
    especialidadesItems() {
      if (this.subCategorias) {
        return this.subCategorias.reduce((acc, curr) => {
          acc.push({ id: curr.subc_id, name: curr.subc_name });
          return acc;
        }, []);
      }
      return [];
    },
    /* categorias */
    categoriesItems() {
      if (this.categorias !== null) {
        return this.categorias.reduce((acc, curr) => {
          acc.push({ id: curr.cate_id, name: curr.cate_name });
          return acc;
        }, []);
      }
      return [];
    },
    /* status */
    statusesItems() {
      if (this.estados !== null) {
        return this.estados.map((c) => c.stat_name);
      }
      return [];
    },

    valores() {
      //si estmos cargando devolvemos un array vacio
      if (this.loading) return [];

      if (this.datos && this.datosTratados) {
        let tmpFiltro = {};

        Object.keys(this.filtro).forEach((e) => {
          if (
            !!this.filtro[e] &&     //true si está definido
            (this.filtro[e].length > 0 || Number.isInteger(this.filtro[e]))
          ) {
            // console.log("entro", e, this.filtro[e]);
            const tKey = e;
            tmpFiltro[tKey] = this.filtro[e];
          }
          if(e=='catNombres'){
            if(!!this.filtro[e] && this.filtro[e].name.length > 0)
              tmpFiltro['specialties'] = this.filtro[e].id;
          }
          if(e=='espNombres'){
            if(!!this.filtro[e] && this.filtro[e].name.length > 0)
              tmpFiltro['subcategorias'] = this.filtro[e].id;
          }
          if(e=='tipoGuia'){
            if(!!this.filtro[e])
              tmpFiltro['tipoGuia'] = this.filtro[e].id;
          }
        });

        //guardamos los filtros en el localstorage
        localStorage.setItem("currentFilter", JSON.stringify(this.filtro));

        // modificado para guardar en el store
        // this.SET_RECORD_FILTER({ ...this.filtro });

        return filtros(this.datosTratados, tmpFiltro);
      }
      return null;
    },
  },

  mounted() {
    if (this.$router.currentRoute.params.localdata === true) {
      this.recargarDatosTabla = true;
      if (this.recordAll.data !== null) {
        this.recargaLocal = true;
      }
    }
    this.refrescarDatos();
  },
};

/* funcion para filtrar los datos */
const filtros = (data, filtros) => {
  var filterKeys = Object.keys(filtros);

  const salida = data.filter(function (eachObj, index) {
    return filterKeys.every(function (eachKey) {
      //comprobamos que el filtro actual no esté vacio
      if (!filtros[eachKey].length && !Number.isInteger(filtros[eachKey])) {
        return true;
      }

      //comprobamos que el filtro actual sea o no de fecha
      if (eachKey.includes("creationdate")) {
        return compararFechas(
          filtros.creationdateIni,
          filtros.creationdateEnd,
          eachObj.creationdate
        );
      }

      //comprobamos si es un filtro de especialidades <- marcado como categorías
      if (eachKey === "specialties") {
        if (eachObj[eachKey].indexOf(filtros[eachKey]) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      //comprobamos si es un filtro de subCategorias <- marcado como categorías
      if (eachKey === "subcategorias") {
        if (eachObj[eachKey].indexOf(filtros[eachKey]) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      // comparar el string categoria
      if (eachKey === "catNombres") {
        let tmp = eachObj[eachKey].join(" ").toLowerCase().trim();
        if (compararRegexStr(filtros[eachKey], tmp)) {
          return true;
        } else {
          return false;
        }
      }
      // comparar el string especialidad
      if (eachKey === "espNombres") {
        let tmp = eachObj[eachKey].join(" ").toLowerCase().trim();
        if (compararRegexStr(filtros[eachKey], tmp)) {
          return true;
        } else {
          return false;
        }
      }
      // filtramos por tipo de guía
      if (eachKey === "tipoGuia") {
        let tmp = eachObj['reco_idguiastipo'];
        //Si no tiene tipo de guía lo devolvemos
        if(!tmp)
          return true;
        if(tmp.indexOf(filtros[eachKey])!=-1)
          return true;
        else
          return false;
      }

      //comparamos un texto cualquiera
      if (compararRegexStr(filtros[eachKey], eachObj[eachKey])) {
        return true;
      }
    });
  });
  return salida;
};

/* funcion para comparar fechas de los filtros */
const compararFechas = (fechaIni, fechaFin, fechaAlta) => {
  //si la fecha de inicio no está seteada devolvemos true y no comparamos
  if (!fechaIni) {
    return true;
  }

  //si no hay fecha fin comparamos las fechas mayor e igula que fechaAlta
  if (!fechaFin) {
    const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
    const mFechaAlta = moment(fechaAlta, "DD/MM/YYYY");

    return mFechaAlta.isAfter(mFechaIni);
  }
  //si está seteada tbn la fecha de fin
  const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
  const mFechaAlta = moment(fechaAlta, "DD/MM/YYYY");
  const mFechaFin = moment(fechaFin, "YYYY-MM-DD").add(1, "d");

  return mFechaAlta.isAfter(mFechaIni) && mFechaAlta.isBefore(mFechaFin);
};

/* function para eliminar los acentos antes de comparar */
const tratarTexto = (texto) => {
  if (!texto) {
    return "";
  }
  const sustituir = { á: "a", é: "e", í: "i", ó: "o", ú: "u" };

  return texto.toLowerCase().replace(/[^\w]/gi, function (str) {
    return sustituir[str] || str;
  });
};

/* funcion para comparar via regex los strings */
const compararRegexStr = (cadena, texto) => {
  let pattern = tratarTexto(texto);
  let _value_ = tratarTexto(cadena.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"));
  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};
</script>

<style scoped>
.v-input {
  font-size: 0.9em;
}
/deep/.v-label {
  font-size: 1em;
}

/deep/.v-list-item__mask {
  background-color: yellow !important;
  color: blue !important;
  font-size: 1.2em !important;
  text-decoration: underline !important;
}
</style>
